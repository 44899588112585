import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import SyntheticAssets from './ProductImages/synthetic_assets_product.png';
import CapitalInterestVault from './ProductImages/capital_interest_vault.png';
import FundBuilder from './ProductImages/fund_builder.png';
import MonetaryStabilityFund from './ProductImages/monetary_stability_fund.png';
import SavingsPocket from './ProductImages/savings_pocket.png';
import TokenizedIndex from './ProductImages/tokenized_index.png';
import TransactionAccumulationFund from './ProductImages/C1_Leverage.png';
import VaultIndexFund from './ProductImages/vault_index_fund.png';
import GalaxyPulse from './ProductImages/galaxy_pulse.png';
import Supernova from './ProductImages/supernova.png';
import WealthCreator from './ProductImages/wealth_creator.png';
import StellaNova from './ProductImages/stellanova.png';
import SolarFlare from './ProductImages/solarflare.png';
import StableFundLoan from './ProductImages/stable_fund_loan.png';
import axios from 'axios'
import { UserContext } from '../../UserContext';

import { CiCoinInsert } from "react-icons/ci";
import { CiBank } from "react-icons/ci";
import { CiFileOn  } from "react-icons/ci";
// Bondcoin Accumalation Vault

import MyLTVLoanProducts from './IndividualProducts/LoanProduc3/myProducts';
import MyLoanProducts from './IndividualProducts/LoanProduct2/myProducts';
import MyCryptoLTVLoanProducts from './IndividualProducts/CryptoLTVLoan/myProducts';

function MyPackages() {

    const{auth,user} = React.useContext(UserContext)
    const [userid, setUserid] = useState(null)
    const [products, setProducts] = useState(null)
    const [holdingProducts, setHoldingProducts] = useState(null)
    const [displayLoader, setDisplayLoader] = useState(true)
    const [showLoanProduct, setShowLoanProduct] = useState(false)
    const [showLTVLoanProduct, setLTVShowLoanProduct] = useState(false)
    const [myProductsActive, setMyProductsActive] = useState(true)
    const [stableFundsActive, setStableFundsActive] = useState(false)
    const [LTVFundsActive, setLTVFundsActive] = useState(false)
    const [selectedMenuItem, setSelectedMenuItem] = useState(1)

    React.useEffect(()=>{
      
      if(auth && user)
      {
        setUserid(user.user.userid)
        getUserProducts(user.user.userid)
        getUserHoldingProducts(user.user.userid)
        // didUserTakeOutLoan(user.user.userid)
        // didLTVUserTakeOutLoan(user.user.userid)
      }
  
    },[auth])

  // Sample data for products (you can replace this with your actual data)
//   const products = [
//     {
//       id: 1,
//       name: 'Product 1',
//       imageUrl: SyntheticAssets,
//       status: 'Active',
//       link: '/product1', // Add the link for Product 1
//     },
//     {
//       id: 2,
//       name: 'Product 2',
//       imageUrl: CapitalInterestVault,
//       status: 'Inactive',
//       link: '/product2', // Add the link for Product 2
//     },
//     {
//       id: 3,
//       name: 'Product 3',
//       imageUrl: FundBuilder,
//       status: 'Active',
//       link: '/product3', // Add the link for Product 3
//     },
//     // Add more product objects as needed
//   ];

const imageContainer = {
  '15': TransactionAccumulationFund,
  '16': SavingsPocket,
  '17': SavingsPocket,
  '18': VaultIndexFund,
  '19': VaultIndexFund,
  '20': VaultIndexFund,
  '21': VaultIndexFund,
  '22': VaultIndexFund,
  '23': VaultIndexFund,
  '24': VaultIndexFund,
  '25': VaultIndexFund,
  '26': SavingsPocket,
  '27': SavingsPocket,
  '28': SavingsPocket,
  '29': TokenizedIndex,
  '30': TokenizedIndex,
  '31': TokenizedIndex,
  '32': TokenizedIndex,
  '33': MonetaryStabilityFund,
  '34': MonetaryStabilityFund,
  '35': MonetaryStabilityFund,
  '36': SyntheticAssets,
  '37': SyntheticAssets,
  '38': SyntheticAssets,
  '39': SyntheticAssets,
  '40': FundBuilder,
  '41': SolarFlare,
  '42': StellaNova,
  '43': GalaxyPulse,
  '44': Supernova,
  '45': WealthCreator,
  '46': WealthCreator,
  '47': WealthCreator,
  '53': SavingsPocket,
  '1': StableFundLoan
}

    const getUserProducts = async(userid) =>{
        var userObj = {
        userid: userid
        }

        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-all-user-products`,userObj).then(async (res)=>{
        if(res.data.status == 200)
        {
        
            // setProducts(res.data.Products)
            generateProductObject(res.data.Products)
        }else{
            setDisplayLoader(false)
        }
        })

        return true;
    }


    var currency = {
        "1": "USDT",
        "2": "ZAR",
        "3": "BTC",
        "5": "USDT Loan",
        "6": "ZAR Loan",
    }

    const generateProductObject = async(products) => {
        var productObj = [];
        for( const product of products)
        {
          
            var productStatus = "";
            if(product.status === "1")
            {
                productStatus = "Active";
            }else if(product.status === "2")
            {
                productStatus = "Inactive";
            }else if(product.status === "0")
            {
                productStatus = "Pending";
            }else if(product.status === "3")
            {
                productStatus = "Expired";
            }

            var newObj = {
                id: product.id,
                // name: 'Product 1',
                imageUrl: imageContainer[product.product_id],
                status: productStatus,
                link: "/single-product?pid=" + product.id, 
                amount: currency[product.currency] + " " + product.amount, 
            }
            productObj.push(newObj);
        }

        setProducts(productObj)
        setDisplayLoader(false)
    }

    const getUserHoldingProducts = async(userid) =>{
      var userObj = {
      userid: userid
      }

      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-all-user-holding-products`,userObj).then(async (res)=>{
      if(res.data.status == 200)
      {
      
          // setProducts(res.data.Products)
          generateHoldingsProductObject(res.data.Products)
      }else{
          setDisplayLoader(false)
      }
      })

      return true;
  }

    const generateHoldingsProductObject = async(products) => {
      var productObj = [];
      for( const product of products)
      {
        
          var productStatus = "";
          if(product.status === "1")
          {
              productStatus = "Active";
          }else if(product.status === "2")
          {
              productStatus = "Inactive";
          }else if(product.status === "0")
          {
              productStatus = "Pending";
          }else if(product.status === "3")
          {
            productStatus = "Topped Up Successfully";
          }

          var newObj = {
              id: product.id,
              // name: 'Product 1',
              imageUrl: imageContainer[product.product_id],
              status: productStatus,
              link: "/single-product?pid=" + product.id, 
              amount: currency[product.currency] + " " + product.amount, 
          }
          productObj.push(newObj);
      }

      setHoldingProducts(productObj)
  }

    const handleItemClick = async(item) => {
      setSelectedMenuItem(item)
    }

  return (
    <div className="container mx-auto px-2 pt-8 pb-8">
      <div className="mb-5">
        <h3 className="mb-6 mt-6 text-xl font-extrabold leading-none tracking-tight md:text-xl lg:text-2xl text-white">
          My Products
        </h3>
       

        <div class="menu-container">
          <div class={`menu-item cursor-pointer ${selectedMenuItem == 1 ? 'menu-item-selected' : ''}`} onClick={(e) => {handleItemClick(1)}} > <CiFileOn /><span>Products</span> </div>
          <div class={`menu-item cursor-pointer ${selectedMenuItem == 2 ? 'menu-item-selected' : ''}`} onClick={(e) => {handleItemClick(2)}}> <CiCoinInsert /><span>Stable Fund Loans</span> </div>
          <div class={`menu-item cursor-pointer ${selectedMenuItem == 3 ? 'menu-item-selected' : ''}`} onClick={(e) => {handleItemClick(3)}}> <CiBank /><span>LTV Loans</span> </div>
          <div class={`menu-item cursor-pointer ${selectedMenuItem == 4 ? 'menu-item-selected' : ''}`} onClick={(e) => {handleItemClick(4)}}> <CiBank /><span>Crypto LTV Loans</span> </div>
          <div class={`menu-item cursor-pointer ${selectedMenuItem == 5 ? 'menu-item-selected' : ''}`} onClick={(e) => {handleItemClick(5)}}> <CiBank /><span>Product Holding Tank</span> </div>
        </div>
      </div>
        {displayLoader ?
        <p>Loading...</p>
        :
      <div >
      {
        selectedMenuItem === 1 ? (
          <>
               <div className="grid grid-cols-2 gap-4">
            {/* <p className="text-white">Click on one of your products for more information.</p> */}
            {products && products.length > 0 ? (
              products.map((product) => (
                <div
                  key={product.id}
                  className="relative bg-white rounded-lg overflow-hidden shadow-md"
                >
                  {/* Overlay for darkening the image */}
                  <Link to={product.link} className="block w-full h-auto">
                    <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>

                    <div
                      className="absolute top-2 left-2 text-white px-2 py-1 rounded"
                      style={{ backgroundColor: '#ad843b' }}
                    >
                      {product.amount}
                    </div>

                    {/* Make each image clickable with a Link */}
                    <img
                      src={product.imageUrl}
                      alt={product.name}
                      className="w-full h-auto"
                    />

                    <div
                      className={`absolute top-2 right-2 text-white px-2 py-1 rounded ${
                        product.status === 'Active' ? 'bg-green-600'
                        : product.status === 'Expired' ? 'bg-red-600'
                        : product.status === 'Pending' ? 'c1-pending-background'
                        : '' // Remove duplicate condition for 'Expired'
                      }`}
                    >
                      {product.status}
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <p className="text-white">No Products Found!</p>
            )}
            </div>
          </>
        ) : null
      }

    {selectedMenuItem === 2 ?
      <MyLoanProducts />
    : ''}

        
        {/* {showLoanProduct ? (
   
            <div
       
              className="relative bg-white rounded-lg overflow-hidden shadow-md"
            >
             
              <Link to={"/product/stable-fund-loan"} className="block w-full h-auto">
                <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>

              
                <img
                  src={StableFundLoan}
                  alt={"Stable Fund Coin"}
                  className="w-full h-auto"
                  style={{maxHeight: "356px"}}
                />

               
              </Link>
            </div>
       
        ) : ''}

    {showLTVLoanProduct ? (
   
   <div

     className="relative bg-white rounded-lg overflow-hidden shadow-md"
   >
    
     <Link to={"/product/ltv-loan"} className="block w-full h-auto">
       <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>

  
       <img
         src={LTVLoan}
         alt={"Stable Fund Coin"}
         className="w-full h-auto"
         style={{maxHeight: "356px"}}
       />

      
     </Link>
   </div>

) : ''} */}

    {selectedMenuItem === 3 ?
      <MyLTVLoanProducts />
      : ''}

    {selectedMenuItem === 4 ?
      <MyCryptoLTVLoanProducts />
      : ''}

{selectedMenuItem === 5 ? (
          <>
               <div className="grid grid-cols-2 gap-4">
            {/* <p className="text-white">Click on one of your products for more information.</p> */}
            {holdingProducts && holdingProducts.length > 0 ? (
              holdingProducts.map((product) => (
                <div
                  key={product.id}
                  className="relative bg-white rounded-lg overflow-hidden shadow-md"
                >
                  {/* Overlay for darkening the image */}
                  {/* <Link to={product.link} className="block w-full h-auto"> */}
                    <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>

                    <div
                      className="absolute top-2 left-2 text-white px-2 py-1 rounded"
                      style={{ backgroundColor: '#ad843b' }}
                    >
                      {product.amount}
                    </div>

                    {/* Make each image clickable with a Link */}
                    <img
                      src={product.imageUrl}
                      alt={product.name}
                      className="w-full h-auto"
                    />

                    <div
                      className={`absolute top-2 right-2 text-white px-2 py-1 rounded ${
                        product.status === 'Active' ? 'bg-green-600'
                        : product.status === 'Expired' ? 'bg-red-600'
                        : product.status === 'Pending' ? 'c1-pending-background'
                        : '' // Remove duplicate condition for 'Expired'
                      }`}
                    >
                      {product.status}
                    </div>
                  {/* </Link> */}
                </div>
              ))
            ) : (
              <p className="text-white">No Products Found!</p>
            )}
            </div>
          </>
        ) : null}

      </div>
        }
    </div>
  );
}

export default MyPackages;
